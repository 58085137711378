<script>
export default {
  components: {},

  computed: {},
};
</script>

<template>
  <div>
    <slot />
  </div>
</template>
<style lang="scss">
body {
  margin: 0;
}
.main-content {
  padding-top: 0;
}
</style>
