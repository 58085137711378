<script>
export default {
  components: {},
  props: {
    timezone: { type: String, default: "" },
    city: { type: String, default: "" },
    color: { type: String, default: "white" },
    isCountdown: { type: Boolean, default: false },
  },
  data() {
    return {
      timezones: {
        GMT: 0,
        UTC: 0,
        CET: 1,
        JST: 9,
        PST: -7,
        EST: -5,
      },
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
      // 16 Mar 2022 7am PST
      futureDate: new Date("09 May 2022 00:00:01 GMT-0800").getTime(),
      countDownInterval: null,
    };
  },
  mounted() {
    if (this.isCountdown) {
      const userTimezoneOffset = new Date().getTimezoneOffset() / 60;
      const offsetToMs = userTimezoneOffset * 60 * 60 * 1000;
      this.futureDate += offsetToMs;

      this.countDownInterval = setInterval(() => {
        const futureDate = new Date(this.futureDate).getTime();
        const nowDate = new Date().getTime();
        const totalMS = futureDate - nowDate;
        const totalSeconds = totalMS / 1000;
        //set to 0 if ramining time is negative
        if (totalMS % 100 < 0) {
          this.milliseconds = this.seconds = this.minutes = this.hours = "00";
          clearInterval(this.countDownInterval);
          return;
        }
        this.milliseconds = this.padZero(Math.floor(totalMS % 100));
        this.seconds = this.padZero(Math.floor(totalSeconds) % 60);
        this.minutes = this.padZero(Math.floor(totalSeconds / 60) % 60);
        this.hours = this.padZero(Math.floor(totalSeconds / 3600));
      }, 1);
    }

    if (!this.isCountdown) {
      setInterval(() => {
        this.milliseconds = this.padZero(
          Math.floor(new Date().getMilliseconds() / 10).toFixed(0)
        );
        this.seconds = this.padZero(new Date().getSeconds());
        this.minutes = this.padZero(new Date().getMinutes());
        let hour =
          new Date().getUTCHours() + (this.timezones[this.timezone] || 0);
        if (hour < 0) hour = 24 + hour;
        if (hour >= 24) hour = Math.abs(24 - hour);
        this.hours = this.padZero(hour);
      }, 1);
    }
  },
  methods: {
    padZero(val) {
      return val < 10 ? "0" + val : "" + val;
    },
  },
  computed: {},
};
</script>

<template>
  <div
    class="timer text-xs lg:text-2xl"
    :style="'color:' + color"
    :class="{ purple: color === '#E91EA4' }"
  >
    {{ hours }}:{{ minutes }}:{{ seconds
    }}<span class="hidden lg:inline">:{{ milliseconds }}</span>
    {{ !isCountdown && city && city.length > 0 ? city : timezone }}
  </div>
</template>

<style lang="scss" scoped>
.timer {
  font-family: "Roboto", "sans serif" !important;
  font-style: normal;
  font-weight: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  @media (min-width: 1024px) {
    font-weight: 400;
    font-size: 23px;
    line-height: 27px;
  }
}
.purple {
  font-size: 23px;
  line-height: 27px;
}
</style>
